<template>
    <v-container fluid class="fill-height ma-0 pa-0">
        <medical-services-component/>
        <footer-component/>
    </v-container>
</template>

<script>
    import MedicalServicesComponent from "@/components/medical-services/MedicalServicesComponent";
    import FooterComponent from '@/layouts/app-layout/footer/FooterComponent.vue';
    
    export default {
        name: "MedicalServices",
        title: "Servicios Médicos | Turismo BC",
        components: {
            MedicalServicesComponent,
            FooterComponent
        }
    }
</script>

<style scoped>

</style>